/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 16 oct. 2017, 11:30:10
    Author     : qledoledec
*/
$blue: #003959;
$lightlightblue: rgba(0, 57, 89, 0.03);

body {
  background: $lightlightblue;
}

.body-centered {
  height: 100vh;
  background: $lightlightblue;
  color: #fff;
  .alerts {
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
}

button, .btn {
  cursor: pointer;
}

h1, h2, h3, h4, h5 {
  color: $blue;
}

h1 {
  margin: 1rem 0 2rem;
}

pre {
  background: $blue;
  color: #FFF;
  padding: 20px;
}

.alerts {
  margin: 1rem 0;
}

em {
  background: #ffeeba;
  font-style: normal;
  /*color: #856404;
  font-weight: bold;*/
}

input[type="range"] {
  position: relative;
  &:after,
  &:before {
    position: absolute;
    top: 1em;
    color: #aaa;
  }
  &:before {
    left: 0;
    content: attr(min);
  }
  &::after {
    right: 0;
    content: attr(max);
  }
}

.popover {
  ul {
    margin: 0;
    padding: 5px 20px;
  }
}

/** DROPZONE */
#dropzone {
  background: #eee;
  border: 2px dashed #aaa;
  padding: 20px;
  border-radius: 3px;
  min-height: 87px;
  margin: 0;
  position: relative;
  cursor: pointer;
  .background {
    color: #aaa;
    font-size: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  &.dz-drag-hover,
  &:hover {
    background: rgba(0, 57, 89, 0.1);
    border-color: #003959;
    .background {
      color: #003959;
    }
  }
  &.dz-started {
    .background {
      display: none;
    }
  }
}

#dropzone {
  .item {
    list-style-type: none;
    margin-bottom: 5px;
    &.dz-error {
      color: #721c24;
      .dz-filename {
        text-decoration: line-through;
      }
      .dz-size {
        text-decoration: line-through;
      }
    }
    .dz-size {
      color: #6c757d;
      margin-left: 20px;
    }
    .dz-remove {
      margin-right: 20px;
    }
    .progress {
      .progress-bar {
        height: 38px;
        line-height: 38px;
      }
    }
  }
}

/** MODULE UTILISATEURS **/
table {
  tr {
    .toggle-admin {
      color: #868e96;
      i {
        &:before {
          content: "\f204";
        }
      }
    }
    &.is-admin {
      /*font-weight:bold;*/
      background: #ffeeba;
      .toggle-admin {
        color: #28a745;
        i {
          &:before {
            content: "\f205";
          }
        }
      }
    }
  }
}